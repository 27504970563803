import React, { useEffect, useState } from "react";

import classes from "./UserForm.module.css";
import useInput from "../UI/useInput";
import { useSelector } from "react-redux";
import Modal from "../UI/Modal";
// import emailjs from "@emailjs/browser";
import { sendOrder } from "../services/orderService";
// import { cartActions } from "../store/store";

// const sectors = [
//   "H-13",
//   "H-12 (Nust)",
//   "H-11",
//   "H-10",
//   "I-10",
//   "I-9",
//   "I-8",
//   "G-14",
//   "G-13",
//   "G-12",
//   "G-11",
//   "G-10",
//   "G-9",
//   "G-8",
//   "G-7",
//   "F-11",
//   "F-10",
//   "F-8",
//   "F-7",
//   "F-6",
//   "E-11",
//   "E-9",
//   "26-Number",
// ];
// const options = ["1", "2", "3", "4", "Markaz"];

const UserForm = (props) => {
  const [formValidity, setformValidity] = useState(false);
  const [orderButton, setOrderButton] = useState("Order");

  const ctx = useSelector((state) => state.cartReducer);

  const orderFormHandler = (e) => {
    e.preventDefault();
    // const localStore = {
    //   food: ctx.items,
    //   totalPrice: ctx.totalPrice,
    //   orderStatus: true,
    // };
    // localStorage.setItem("ORDER", JSON.stringify(localStore));

    // const EMAIL_DATA = {
    //   from_name: "BAba Jani' Website",
    //   to_name: "BAba Jani's Fast Food",
    //   message: `Name: ${order.name}
    //   Address: ${order.address}
    //   Phone: ${order.phone}\n
    //   Ordered Items: ${ctx.items
    //     .map((item) => `${item.name}, ${item.amount} \n`)
    //     .join("")}
    //   Total Price: ${order.totalPrice}`,
    // };
    setOrderButton("Order ◌");
    const currentTime = new Date();
    const formattedTime = `${currentTime.getDate().toString()}/${(
      currentTime.getMonth() + 1
    ).toString()}--${currentTime.getHours().toString()}:${currentTime
      .getMinutes()
      .toString()}`;

    const order = {
      name: enteredName,
      address: ctx.addressD,
      phone: enteredPhone,
      time: formattedTime,
      food: ctx.items,
      totalPrice: ctx.totalPrice + ctx.delivery,
    };
    // console.log(order);
    sendOrder(order)
      .then((response) => {
        console.log("ORDER sent successfully:", response);
        props.onFormOrder();
      })
      .catch((error) => {
        props.onFormError();
        window.alert(
          "Error while Placing Order ☹ . \n Please Try Again. OR Use Contact Number to Order.!"
        );
        console.log("Error while sending ORDER:", error);
      });
    // emailjs
    //   .send(
    //     "service_2up96cq",
    //     "template_1e7db49",
    //     EMAIL_DATA,
    //     "B7kaN_EiTgNKIDFV0"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
  };
  const {
    enteredValue: enteredName,
    valueValidity: nameValidity,
    classInvalid: nameClassInvalid,
    valueChangeHandler: nameChangeHandler,
    valueBlurHandler: nameBlurHandler,
  } = useInput((name) => {
    return name.trim().length >= 3;
  });
  const {
    enteredValue: enteredPhone,
    valueValidity: phoneValidity,
    classInvalid: phoneClassInvalid,
    valueChangeHandler: phoneChangeHandler,
    valueBlurHandler: phoneBlurHandler,
  } = useInput((phone) => {
    return phone.toString().trim().length >= 11;
  });
  useEffect(() => {
    if (nameValidity && phoneValidity) {
      setformValidity(true);
    } else {
      setformValidity(false);
    }
  }, [nameValidity, phoneValidity]);

  const nameClass =
    !nameClassInvalid && nameValidity
      ? classes.input + " " + classes.valid
      : classes.input;
  const addressClass = classes.input;
  const phoneClass =
    !phoneClassInvalid && phoneValidity
      ? classes.input + " " + classes.valid
      : classes.input;

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <Modal onCartClose={props.onFormClose}>
      <div className={classes.form}>
        {/* <header>Your Deatils</header>
        {cartItems}
        */}
        <form onSubmit={orderFormHandler}>
          <div className={nameClass}>
            <label htmlFor="name" style={{ width: "min-content" }}>
              Name
            </label>
            <input
              id="name"
              type={"text"}
              value={enteredName}
              onChange={nameChangeHandler}
              onBlur={nameBlurHandler}
            />
            {nameClassInvalid && <span>Please Enter Valid Name-!</span>}
          </div>
          <div className={phoneClass}>
            <label htmlFor="phone" style={{ width: "min-content" }}>
              Phone
            </label>
            <input
              id="phone"
              type={"number"}
              value={enteredPhone}
              onChange={phoneChangeHandler}
              onBlur={phoneBlurHandler}
              className={classes.number_input}
            />
            {phoneClassInvalid && (
              <span>Please Enter Valid Phone Number-!</span>
            )}
          </div>
          <div className={addressClass}>
            <label htmlFor="address" style={{ width: "min-content" }}>
              Address
            </label>
            <input id="address" value={ctx.addressD} disabled={true} />
          </div>
          {ctx.totalPrice ? (
            <>
              <div className={classes.total}>
                <span>Order : </span>
                <span>{ctx.totalPrice}</span>
              </div>
              <div className={classes.total}>
                <span>Delivery : </span>
                <span> + {ctx.delivery}</span>
              </div>
              <div
                className={classes.total}
                style={{ fontSize: "x-large", marginTop: "20px" }}
              >
                <span>Total : </span>
                {ctx.delivery && (
                  <span>Rs.{ctx.delivery + ctx.totalPrice}</span>
                )}
              </div>
            </>
          ) : (
            ""
          )}

          <div className={classes.actions}>
            <button
              className={classes["button--close"]}
              onClick={props.onFormClose}
            >
              Cancel
            </button>
            <button className={classes.button} disabled={!formValidity}>
              {orderButton}
            </button>
          </div>
        </form>
        {/* {loading && <p>Loaading.....</p>} */}
        {/* {error && <p>Something Went Wrong...</p>} */}
      </div>
    </Modal>
  );
};

export default UserForm;
