import React from "react";
import classes from "./Header.module.css";

import CartButton from "../Cart/CartButton";
import Logo from "../assets/logo.png";
import BabaJani from "../assets/BabaJani.svg";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate("/");
  };
  return (
    <>
      <header className={classes.header}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={Logo}
            className={classes.logo}
            height="100px"
            width="auto"
            alt="BAba Jani's Logo"
            onClick={clickHandler}
          />
          <img
            src={BabaJani}
            className={classes.heading}
            height="70px"
            width="auto"
            alt="BAba Jani's Fast Food H-13"
            
          />
        </div>
        <CartButton onCartOpen={props.onCartOpen} />
      </header>
    </>
  );
};

export default Header;
